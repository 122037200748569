<template v-slot:no-data>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
      
            <v-row style="margin-top: 4px;">
              <v-col style="margin-top: -12px;">
                <template>
                  <s-crud
                  title="Kilometros por Zona"
                    :config="configCrud"
                    @save="save($event)"
                    edit
                    add
                    remove
                    :filter="filter"
                    search-input
                    no-full
                    
                  >
                    <template slot-scope="props">
                      <v-container>
                        <v-row style="margin-bottom: 10px;" justify="center">

                          <v-col
                            lg="4"
                            md="4"
                            cols="12"
                          >
                            <s-select-generic
                              label="Punto de Partida"
                              itemtext="ZonName"
                              itemvalue="ZonID"
                              id="ZonName"
                              full
                              autocomplete
                              clearable
                              :config="configZon"
                              v-model="props.item.ZonIDP"
                            >
                            </s-select-generic>
                          </v-col>

                          <v-col
                            lg="4"
                            md="4"
                            cols="12"
                          >
                            <s-select-generic
                              label="Punto de Llegada"
                              itemtext="ZonName"
                              itemvalue="ZonID"
                              id="ZonName"
                              full
                              autocomplete
                              clearable
                              :config="configZon"
                              :value="24"
                              v-model="props.item.ZonIDL"
                            >
                            </s-select-generic>
                          </v-col>

                           <v-col
                            lg="2"
                            md="2"
                            cols="12"
                          >
                            <s-text 
                                label="Distancia (KM)" 
                                number 
                                v-model="props.item.TrzDistance"
                            ></s-text>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                  </s-crud>
                </template>
              </v-col>
            </v-row>
      
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
//Service
import _sTransportZonKmService from "../../../services/Technicalassistance/TransportZonKmService";
import _sTransportPriceService from "../../../services/Technicalassistance/TransportPriceService";


export default {
  components: {},
  props: {
  },
  data: () => ({
    filter: {},
    configCrud: {
      service: _sTransportZonKmService,
      model: {
        TrzID: "ID",
      },
      headers: [
        { text: "Punto Partida",  value: "ZonNameP",        width: 130 },
        { text: "Punto Llegada",  value: "ZonNameL",        width: 130 },
        { text: "KM",             value: "TrzDistance",     width: 20 },
      ],
    },

  }),

  created() {
    this.configZon = {
      url: _sTransportPriceService.GetZon(),
      title: this.label,
      params: { search: "", requestID: this.$fun.getUserID() },
    };
  },
  methods: {

    save(val) {

      if(val.ZonIDP == undefined){
          this.$fun.alert("Debe especificar un Punto de Partida", "warning");
          return;
      }

      if(val.ZonIDL == undefined){
         this.$fun.alert("Debe especificar un Punto de LLegada", "warning");
          return;
      }

      if(val.TrzDistance.length == 0){
         this.$fun.alert("Ingrese Distancia (KM)", "warning");
          return;
      }

      val.SecStatus = 1;
      val.UsrCreateID = this.$fun.getUserID();

      val.save();

      console.log("Guardar", val);
    },

  },
};
</script>
